body.active-modal {
  overflow-y: hidden;
  font-family: 'Poppins', sans-serif;
}

.btn-modal {
  padding: 0px 0px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}
.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal {
  width: 90vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 100;
}

.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.modal-content {
  position: relative;
  top: 40%;
  left: 87%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 0px 14px;
  padding-bottom: 0px;
  border-radius: 3px;
  max-width: 30%;
  min-width: 300px;
  height: 80%;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}
